import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0035 = () => (
  <Wrapper>
    <ColumnH1 label="代休とは？付与する際に気を付けるべき点を解説します" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.12.14</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働者保護の観点から労働基準法では毎週少なくとも1回の休日を設けるように規定しています。また、起算日を明らかにすれば4週間に4日以上の休日を与えることも可能とされています。
        <br />
        しかし、やむを得ず休日出勤させた場合は<span>「代休」</span>
        として、代わりの休みを付与することとなりますが、代休を付与するにあたって法律上注意しなければならない論点があることから、確認していきましょう。
      </p>
      <img src="/images/column/details/c0035.jpg" alt="キンクラコラム画像35" />
      <h1>休日とは</h1>
      <p>
        まずは、代休を理解する前に休日を理解する必要があります。労働基準法第35条に休日の定めがあり、端的には
        <span>1週間に1回1日の休日</span>
        を付与すれば違法とはなりません。尚、国民の祝日については、休ませるに越したことはありませんが、週1日の休日が確保されていれば休ませなくとも違法とはなりません。
        <br />
        <br />
        また、休日は休憩のように一斉に付与すべき義務はありません。また、休日の「特定」までは法律上求められていません。しかし、労働者保護の観点から特定することが望ましいとされており、法の趣旨から見ても労働者に対してどの日が休日であるかを特定する方が望ましいことは明らかです。
        <br />
        <br />
        休日とは労働義務がない日であり、会社としても賃金支払い義務はありません。よって、会社から特別な要請を受けない限り労働者は休日に働かないことをもって、制裁を受けることはありません。
        <br />
        <br />
        「4週間に4日以上の休日を与えることでも可能」の解釈について、特定の4週間に4日の休日が確保されていればよく、どの4週間を区切っても4日の休日が与えられていなければならないということではありません。
      </p>
      <h1>代休とは</h1>
      <p>
        <span>休日労働させ、事後に代償措置として代わりの休日を与えること</span>
        を代休と呼びます。これは、「休日労働した」という事実は代償措置によって消えることはなく、休日労働の扱いのままとなります。実務上最も重要な論点として代休を付与した場合でも休日労働に対する
        <span>割増賃金の支払いは必要</span>となる点が挙げられます。
        <br />
        <br />
        就業規則に特段の定めがない場合、週の起算日は日曜日が起点となります。例えば日曜日が休日の会社で、日曜日に出勤させ、その翌々日の火曜日に代休を付与したとします。その場合であっても休日労働したという事実は消えないことから、代わりに与える休日の賃金（100％分）と相殺して割増部分にあたる35％分の支払いが必要です。
        <br />
        <br />
        当然、代休に当たっての取り扱いは就業規則に明記しておくことでトラブル回避にも繋がります。
        <br />
        <br />
        尚、「代休」という法律条文はなく、代休を設けるか否かは事業主の裁量によります。よって、休日労働をさせたあとに必ずしも代休を付与しなければならないということにはなりません。しかし、休日労働させ、その翌日も引き続き通常の週と同様の勤務形態を敷く場合、「週単位」で長時間労働となり、蓄積することで36協定での協定時間を超える時間外労働が行われたことによる法違反等が起こり得ます。また、法違反以前に労働者の健康問題も無視できないでしょう。
        <br />
        <br />
        また、就業規則へ記載をする際のポイントについても触れます。
        <br />
        「代休」について、法律上の規定がないとから、労使間で自由に決定することが可能ですが、代休を付与する期間が1か月を超えてしまうと労働基準法第24条で規定する
        <span>「賃金全額払い」違反（休日割増賃金の未払い）</span>
        となる場合もあるため、長くとも1か月以内に留めておくことが望ましいと考えます。何時間の休日出勤で代休の取得を可能とするかについては、まずは
        <span>36協定で定めた休日労働の始業及び終業の時刻を超えないこと</span>
        が前提となります。その範囲内で定めることになりますが、健康確保の観点から、半日以上の休日労働であれば取得可能とする企業もあります。いずれの場合も代休は事後に休日を付与するために割増賃金の支払いが生じる点は必ず押さえておくべき論点です。
      </p>
      <Introduction002 />
      <h1>代休において法違反にならないための準備</h1>
      <p>
        まずは、緊急的な場合を除き、慢性的に休日労働になってしまう体質の場合は
        <span>業務フローの見直し</span>
        が急務です。事業の必要性が高いと言っても労働基準法違反を犯してまで業務を行うことは適切ではなく、労働者の帰属意識の低下も否定できません。
        <br />
        また、休日出勤した日の属する給与計算において、
        <span>割増賃金が支払われているかの精査</span>
        が必要です。割増賃金は強行法規の一つであり、会社と労働者との間で割増賃金を支払わないという合意書を取り交わしていても無効と解されます。特に慢性的かつ複数の労働者が代休を行使している場合、給与計算は慎重に確認すべきです。
      </p>
      <h1>雇用形態によって解釈は変わるのか？</h1>
      <p>
        労働者であれば正社員、パート、アルバイトなどの名称は問わず、代休を始めとした労働関係諸法令が適用されます。当然、正社員のみ代休時の割増賃金を支払うという定めは違法となります。
      </p>
      <h1>変形労働時間制と代休</h1>
      <p>
        変形労働時間制は1か月変形や1年変形など複数の定めがありますが、休日に関する規制もあることから、代休という概念も生じます。当初から休日と定められていた日に出勤させ、代休を付与した場合、通常の固定時間制の労務管理と同様に割増部分の支払いが必要となります。
      </p>
      <h1>管理監督者と代休</h1>
      <p>
        労働基準法で規定する<span>「管理監督者」</span>
        は休日に関する規制がありません。よって、必ずしも代休を付与しなければならないということにはなりませんが、管理監督者であっても健康問題は無視すべきではないため、健康問題が疑われる前に別途休日を付与するなどして心身の疲労回復に充てる日は積極的に考慮すべきです。
      </p>
      <h1>最後に</h1>
      <p>
        有給休暇や休日と異なり、代休という法律条文はなく、有給休暇と代休はどちらを優先すべきかとの議論もあります。
        <br />
        しかし、<span>有給休暇は労働者自身が時季を指定するもの</span>
        であり、会社が労働者の同意なく有給休暇を付与するということはできません。代休については会社の裁量で設けることは可能であり、会社の指定によって代休を付与しても問題はありません。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0035
