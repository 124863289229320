import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0035 from '../../../components/molecules/columnDetailInner0035'
import ColumnRelationCards0035 from '../../../components/molecules/columnRelationCards0035'
import UsefulFunctionLayout009 from '../../../components/molecules/usefulFunctionLayout009'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0035 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '代休とは？',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="代休とは？付与する際に気を付けるべき点を解説します"
        description="休日労働させ、事後に代償措置として代わりの休日を与えることを「代休」と呼びます。代休を付与した場合の割増賃金や注意事項を解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0035/"
        ogType="article"
        ogTitle="代休とは？付与する際に気を付けるべき点を解説します"
        ogDescription="休日労働させ、事後に代償措置として代わりの休日を与えることを「代休」と呼びます。代休を付与した場合の割増賃金や注意事項を解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0035.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0035 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout009 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0035 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0035

export const pageQuery = graphql`
  query C0035 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
